// extracted by mini-css-extract-plugin
export var backgroundColor = "TopSellerReworked-module--backgroundColor--Cx3yw";
export var category = "TopSellerReworked-module--category--3ISB0";
export var container = "TopSellerReworked-module--container--uUrNj";
export var cross = "TopSellerReworked-module--cross--jaode";
export var crossHolder = "TopSellerReworked-module--crossHolder--S-CRA";
export var heading = "TopSellerReworked-module--heading--Y8fyV";
export var heart = "TopSellerReworked-module--heart--Ut4FK";
export var heartHolder = "TopSellerReworked-module--heartHolder--kv6-d";
export var holder = "TopSellerReworked-module--holder--Mu2AL";
export var hoverHeartClass = "TopSellerReworked-module--hoverHeartClass--MtFj4";
export var icon = "TopSellerReworked-module--icon--9lerD";
export var iconHolder = "TopSellerReworked-module--iconHolder--eWr98";
export var link = "TopSellerReworked-module--link--ms7xY";
export var mwst = "TopSellerReworked-module--mwst--VBHvT";
export var name = "TopSellerReworked-module--name--ZvUJf";
export var price = "TopSellerReworked-module--price--EYQxd";
export var refPrice = "TopSellerReworked-module--refPrice--mr1GQ";
export var saleBadge = "TopSellerReworked-module--saleBadge--cWX8j";
export var zIndex999 = "TopSellerReworked-module--zIndex999--xDsYY";
import Layout from '../../components/Navigation/layout'
import * as React from "react";
import {FC, useEffect, useState} from "react";
import FilterField from "../../components/FilterField/FilterField";
import {Button, Col, Container, Row} from "react-bootstrap";
// @ts-ignore
import * as style from './index.module.scss';
import FreeShippingBanner from "../../components/FreeShippingBanner/FreeShippingBanner";
import TopSellerReworked from "../../components/TopSellerReworked/TopSellerReworked";
import Loading from "../../components/Loading/Loading";
import AccountBanner from "../../components/AccountBanner/AccountBanner";
import MarketplaceFilter from "./MarketplaceFilter/MarketplaceFilter";
import {Client, handleApiError} from "../../services/ApiService";
import {toast} from "react-toastify";
import BlurContent from "../../components/BlurContent/BlurContent";
import {
    faChevronDoubleLeft,
    faChevronDoubleRight,
    faChevronRight,
    faChevronLeft
} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Helmet} from "react-helmet";


const MarketplacePage: FC = ({}) => {
    const [products, setProducts] = useState([]);
    const [maxProducts, setMaxProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [wrapperHeight, setWrapperHeight] = useState(0);
    const [page, setPage] = useState(0);
    const [isMobile, setIsMobile] = useState(false)
    const [isSticky, setIsSticky] = useState(false)
    const [metaDescription, setMetaDescription] = useState('');

    const pageSize = 42;

    useEffect(() => {
        Client.Raw.get('SiteConfig').then((res) => {
            setMetaDescription(res.data[0].MetaDescriptionMarketplace);
        }).catch(handleApiError);
    })

    const changeNav = () => {
        if (window.scrollY >= 120) {
            setIsSticky(true)
        } else {
            setIsSticky(false);
        }
    }

    window.addEventListener('scroll', changeNav);


    useEffect(() => {
    }, [page]);

    useEffect(() => {
        setWrapperHeight(window.innerHeight > 1000 ? window.innerHeight : 1000)
    }, [products, window.innerHeight])


    useEffect(() => {
        setIsMobile(window.innerWidth < 991)
    }, [window.innerWidth])

    useEffect(() => window.scrollTo(0, 0), [page, window, loading]);


    return (
        <>
            <Helmet>
                <meta name="description" content={metaDescription}/>
            </Helmet>
            <Layout pageTitle="Marktplatz">
                <div className={style.marketPlaceWrapper} id={'marketplacewrapper-js'}>
                    {!isMobile ? <div className={style.normalFilter}>
                        <MarketplaceFilter setPage={setPage} page={page} filteringDone={setProducts}
                                           filteringDoneMax={setMaxProducts}
                                           filterHeight={wrapperHeight} setLoading={setLoading} propsProducts={[]}
                                           cats={false} filterID={''} showFilter={false}/>
                    </div> : null}
                    <Container className={style.mobileContainer + " py-5 ps-5 ps-sm-0"}>
                        {isMobile ? <div className={style.mobileFilter + (isSticky ? ' ' + style.isSticky : '')}>
                            <MarketplaceFilter setPage={setPage} page={page} filteringDone={setProducts}
                                               filteringDoneMax={setMaxProducts}
                                               filterHeight={wrapperHeight} setLoading={setLoading} propsProducts={[]}
                                               cats={false} filterID={''} showFilter={false}/>
                        </div> : null}
                        {loading ? <Loading type={"border"} message={"Produkte werden geladen"}/> :
                            <>
                                <TopSellerReworked products={products}/>
                                {products.length > 0 ?
                                    <div>
                                        <div
                                            className={'d-flex gap-3 align-items-center justify-content-center text-center ' + style.flexButtonContainer}>
                                            <div className={style.buttonItem}>
                                                {page > 0 ? <Button className={style.customButton}
                                                                    onClick={() => {
                                                                        setPage(0);
                                                                        window.scrollTo(0, 0)
                                                                    }}><span className={"d-lg-block d-none"}>Zur ersten Seite</span><span
                                                        className={"d-lg-none d-block"}><FontAwesomeIcon
                                                        icon={faChevronDoubleLeft}/></span></Button> :
                                                    <Button className={style.customButton + ' ' + style.disabledButton}
                                                            disabled
                                                            onClick={() => {
                                                                setPage(0);
                                                                window.scrollTo(0, 0)
                                                            }}><span
                                                        className={"d-lg-block d-none"}>Zur ersten Seite</span><span
                                                        className={"d-lg-none d-block"}><FontAwesomeIcon
                                                        icon={faChevronDoubleLeft}/></span></Button>}
                                            </div>

                                            <div className={style.buttonItem}>
                                                {page > 0 ? <Button className={style.customButton}
                                                                    onClick={() => {
                                                                        setPage(page => --page);
                                                                        window.scrollTo(0, 0)
                                                                    }}><span
                                                        className={"d-lg-block d-none"}>Zurück</span><span
                                                        className={"d-lg-none d-block"}><FontAwesomeIcon
                                                        icon={faChevronLeft}/></span></Button> :
                                                    <Button className={style.customButton + ' ' + style.disabledButton}
                                                            disabled
                                                            onClick={() => {
                                                                setPage(page => --page);
                                                                window.scrollTo(0, 0)
                                                            }}><span className={"d-lg-block d-none"}>Zurück</span><span
                                                        className={"d-lg-none d-block"}><FontAwesomeIcon
                                                        icon={faChevronLeft}/></span></Button>}
                                            </div>

                                            <div className={style.buttonItem}>
                                                <span>{page + 1} / {Math.ceil(maxProducts.length / pageSize)}</span>
                                            </div>


                                            <div className={style.buttonItem}>
                                                {products.length > 0 ?
                                                    <div>
                                                        {maxProducts.length <= pageSize || products.length != pageSize ?
                                                            <Button
                                                                className={style.customButton + ' ' + style.disabledButton}
                                                                disabled
                                                                onClick={() => {
                                                                    setPage(page => ++page);
                                                                    window.scrollTo(0, 0)
                                                                }}>
                                                                <span className={"d-lg-block d-none"}>Weiter</span><span
                                                                className={"d-lg-none d-block"}><FontAwesomeIcon
                                                                icon={faChevronRight}/></span>
                                                            </Button> :
                                                            <Button className={style.customButton}
                                                                    onClick={() => {
                                                                        setPage(page => ++page);
                                                                        window.scrollTo(0, 0)
                                                                    }}>
                                                                <span className={"d-lg-block d-none"}>Weiter</span><span
                                                                className={"d-lg-none d-block"}><FontAwesomeIcon
                                                                icon={faChevronRight}/></span>
                                                            </Button>}
                                                    </div>
                                                    :
                                                    <Button className={style.customButton}
                                                            onClick={() => {
                                                                setPage(page => ++page);
                                                                window.scrollTo(0, 0)
                                                            }}>Weitere Produkte
                                                        anzeigen</Button>
                                                }
                                            </div>

                                            <div className={style.buttonItem}>
                                                {maxProducts.length <= pageSize || products.length != pageSize ?
                                                    <Button className={style.customButton + ' ' + style.disabledButton}
                                                            disabled
                                                            onClick={() => {
                                                                setPage(0);
                                                                window.scrollTo(0, 0)
                                                            }}><span
                                                        className={"d-lg-block d-none"}>Zur letzten Seite</span><span
                                                        className={"d-lg-none d-block"}><FontAwesomeIcon
                                                        icon={faChevronDoubleRight}/></span></Button> :
                                                    <Button className={style.customButton}
                                                            onClick={() => {
                                                                setPage(Math.ceil(maxProducts.length / pageSize) - 1);
                                                                window.scrollTo(0, 0)
                                                            }}><span
                                                        className={"d-lg-block d-none"}>Zur letzten Seite</span><span
                                                        className={"d-lg-none d-block"}><FontAwesomeIcon
                                                        icon={faChevronDoubleRight}/></span></Button>}
                                            </div>
                                        </div>
                                    </div> : null}
                            </>
                        }
                    </Container>

                </div>
            </Layout>
        </>
    )
}

export default MarketplacePage;
